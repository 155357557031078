<template>
	<div class="bg-w">
		<!-- height: calc(100vh - 60px); -->
		<div class="pd20" style="">
			<div class="cash-pay-header">
				<div class="flex alcenter space">
					<div class="ft18 cl-main" v-if="cashierCartTabType == 'goods'">
						<i class="iconfont iconmall_cate ft16"/>
						商品结算
					</div>
					<div class="ft18 cl-main" v-if="cashierCartTabType == 'taocan'">
						<i class="iconfont iconmall_cate ft16"/>
						套餐结算
					</div>
					<div class="ft18 cl-main" v-if="cashierCartTabType == 'chongzhi'">
						<i class="iconfont iconmall_cate ft16"/>
						充值结算
					</div>
					<div class="ft18 cl-main" @click="$router.go(-1)">
						收起
						<i class="iconfont iconarrowright_black ft16"/>
					</div>
				</div>
			</div>
			
			<div class="cash-pay-content">
				<div class="flex" style="height: 100%;">
					<div class="cash-pay-list pb20">
						<div class="pd20">
							<div class="mt20 text-center ft20 cl-main">
								收账单(未支付)
							</div>
							
							<div class="mt10">
								<div class="flex alcenter">
									<div v-if="JSON.stringify(cashierMember)=='{}'" class="cl-price flex alcenter" style="line-height: 1;" @click="chooseMemberAct()">
										<i class="iconfont iconorder_operation_coach ft12  mr5" />
										选择会员
									</div>
									<div v-else>
										<div class="cl-theme flex alcenter" style="line-height: 1;" @click="chooseMemberAct()">
											{{cashierMember.nick_name}} <div class="ml5 cl-red" @click="chooseMemberAct()">重新选择</div>
										</div>
										<div v-if="cashierMember.level === null" style="padding-top:5px;">
											{{'普通会员'}}
										</div>
										<div v-else style="padding-top:5px;">
											{{cashierMember.level.name + '（' + cashierMember.level.discount + '折）'}}
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="" style="height: 580px;" v-if="cashierCartTabType == 'goods'">
							<a-table :columns="columns" :data-source="getDatas" 
							:pagination="false"  
							:scroll="{y: 500}"
							>
							
								<div slot="name" slot-scope="name,record">
									<a-tooltip>
									    <template slot="title">
									     {{name}}
									    </template>
									    <div class="text-over4">{{name}}</div>
									  </a-tooltip>
								</div>
							    <div slot="sku" slot-scope="record">
									{{record.sku_id>0 ? record.sku_name : '-'}}
								</div>
								
								<div slot="price" slot-scope="price,record">
									￥{{price}}
								</div>
								<div slot="is_vip_discount" slot-scope="is_vip_discount,record">
									{{is_vip_discount === 0 ? '否' : '是'}}
								</div>
								<div slot="total_price" slot-scope="total_price,record">
									￥{{total_price}}
								</div>
							 </a-table>
						</div>
						
						<div class="pd20" v-if="cashierCartTabType == 'goods'">
							<!-- <div class="flex alcenter space cl-main">
								<div>总数量</div>
								<div>{{getTotalNum}}个</div>
							</div> -->
							<div class="flex alcenter space  cl-main">
								<div>总价</div>
								<div>{{getTotalPrice}}元</div>
							</div>
							
							<div class="flex alcenter space mt10 ">
								<div class="cl-info">会员优惠</div>
								<div class="cl-green">{{getYouhuiPrice}}元</div>
							</div>
							<div class="flex alcenter space mt10 ">
								<div class="cl-info">需付</div>
								<div class="cl-price">{{getNeedPay}}元</div>
							</div>
						</div>
						<div class="" style="height: 580px;" v-if="cashierCartTabType == 'taocan'">
							<a-table :columns="taocanColumns" :data-source="getDatas" 
							:pagination="false"  
							:scroll="{y: 500}"
							>
							
								<div slot="name" slot-scope="name,record">
									<a-tooltip>
									    <template slot="title">
									     {{name}}
									    </template>
									    <div class="text-over4">{{name}}</div>
									  </a-tooltip>
								</div>
								<div slot="price" slot-scope="price,record">
									￥{{price}}
								</div>
								<div slot="total_price" slot-scope="total_price,record">
									￥{{total_price}}
								</div>
							 </a-table>
						</div>
						<div class="" style="height: 580px;" v-if="cashierCartTabType == 'chongzhi'">
							<a-table :columns="chongzhiColumns" :data-source="getDatas" 
							:pagination="false"  
							:scroll="{y: 500}"
							>
							
								<div slot="name" slot-scope="name,record">
									充值
								</div>
							    
								
							 </a-table>
						</div>
					</div>
					<div class="cash-pay-type">
						<div class="cash-pay-type-content">
							<div class="cash-pay-type-content-header">
								<div class="flex" style="height: 100%;">
									<div class="cash-pay-type-content-header-item alcenter flex center" 
									v-for="(item,index) in pay_type" :key="index"
									 :style="{width : getWidth +'%'}"
									 :class="{active :selectCurrent== item.key,blNone :index == 0 ,brNone:index==pay_type.length-1}"
									 @click="chooseIt(index)"
									 ><i class="iconfont ft22 " :style="{color:item.color}" :class="item.icon"/>{{item.name}}</div>
								</div>
							</div>
							
							<div class="mt10">
								<div v-if="selectCurrent=='cash'">
									<cash-pay></cash-pay>
								</div>
								<div v-if="selectCurrent=='wechat'">
									<wechat-pay></wechat-pay>
								</div>
								<div v-if="selectCurrent=='money'">
									<money-pay></money-pay>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div v-if="chooseMemberVisible">
				<choose-member :visible="chooseMemberVisible" @cancel="cancelChooseMember" @ok="okChooseMember"></choose-member>
			</div>
		</div>
	</div>
</template>

<script>
	import cashPay from './components/pay/cash.vue';
	import wechatPay from './components/pay/wechat.vue';
	import moneyPay from './components/pay/money.vue';
	import chooseMember from './components/Index/cashRegister/modal/chooseMember.vue'
	export default{
		components:{
			cashPay,
			wechatPay,
			moneyPay,
			chooseMember,
		},
		data(){
			return {
				chooseMemberVisible:false,
				// pay_type:[
				// 	{name:'现金结算',icon:'iconclient_tab04',color:'#01A9EE',key:'cash'},
				// 	// {name:'扫码枪支付',icon:'',key:'scran'},
				// 	{name:'微信支付',icon:'iconwepay',color:'#09BB0D',key:'wechat'},
				// ],
				selectCurrent:'cash',
				columns: [
					{title: '名称',dataIndex: 'name',align: 'center',width:100,scopedSlots: {customRender: 'name'}},
					{title: '规格',key: 'sku',align: 'center',scopedSlots: {customRender: 'sku'}},
					{title: '价格',dataIndex: 'price',align: 'center',scopedSlots: {customRender: 'price'}},
					{title: '数量',dataIndex: 'num',align: 'center'},
					{title: '是否参与折扣',dataIndex: 'is_vip_discount',align: 'center',scopedSlots: {customRender: 'is_vip_discount'}},
					{title: '金额',dataIndex: 'total_price',align: 'center',scopedSlots: {customRender: 'total_price'}},
				],
				taocanColumns: [
					{title: '名称',dataIndex: 'name',align: 'center',width:100,scopedSlots: {customRender: 'name'}},
					{title: '价格',dataIndex: 'price',align: 'center',scopedSlots: {customRender: 'price'}},
					{title: '数量',dataIndex: 'num',align: 'center'},
					{title: '金额',dataIndex: 'total_price',align: 'center',scopedSlots: {customRender: 'total_price'}},
				],
				chongzhiColumns: [
					{title: '名称',dataIndex: 'name',align: 'center',width:100,scopedSlots: {customRender: 'name'}},
					{title: '充值金额',dataIndex: 'recharge_price',align: 'center'},
					{title: '赠送金额',dataIndex: 'give_price',align: 'center'},
					{title: '到账金额',dataIndex: 'daozhang_price',align: 'center'},
				],
				datas:[],
			}
		},
		created(){
			
		},
		computed:{
			pay_type(){
				let arr= [
					{name:'现金结算',icon:'iconclient_tab04',color:'#01A9EE',key:'cash'},
					// {name:'扫码枪支付',icon:'',key:'scran'},
					// {name:'微信支付',icon:'iconwepay',color:'#09BB0D',key:'wechat'},
				];
				this.selectCurrent='cash'
				if(JSON.stringify(this.cashierMember) != '{}'){
					arr.unshift({
						name:'余额结算',icon:'iconmoney_setting',color:'#FF8400',key:'money'
					})
					
					this.selectCurrent='money'
				}
				
				return arr;
			},
			getWidth(){
				let len = this.pay_type.length;
				return 100/len;
			},
			getDatas(){
				let price=0;
				let arr=new Array;
				if(this.cashierCartTabType == 'goods')
				{
					for (var i in this.cashierCart){
						arr[i]=this.cashierCart[i];
						
						if(this.cashierCart[i].sku_id>0){
							price=this.cashierCart[i].sku_original_price;
						}else{
							price=this.cashierCart[i].original_price;
						}
						if(JSON.stringify(this.cashierMember)!='{}'){
							if(this.cashierMember.level_id>0){
								if(this.cashierCart[i].sku_id>0){
									price=this.cashierCart[i].sku_vip_price;
								}else{
									price=this.cashierCart[i].vip_price;
								}
							}
						}
						arr[i].price=price;
						if(this.cashierCart[i].member_once_card_id)
						{
							arr[i].total_price=0;//如果是选择的套餐里面的话	
						}else{
							
							arr[i].total_price=((parseFloat(arr[i].price)*100 * arr[i].num)/100).toFixed(2);
						}
						
						arr[i].key=i+1;
					}
				}else if(this.cashierCartTabType == 'taocan')
				{
					for (var i in this.cashierCartTaocan.list){
						arr[i]=this.cashierCartTaocan.list[i];
						
						price=this.cashierCartTaocan.list[i].original_price;
						arr[i].price=price;
						arr[i].total_price=((parseFloat(arr[i].price)*100 * arr[i].num)/100).toFixed(2);
						arr[i].key=i+1;
					}
				}else if(this.cashierCartTabType == 'chongzhi')
				{
					arr[0] = {
						key:0,
						name:'充值',
						recharge_price:this.cashierCartRecharge.recharge_price?parseFloat(this.cashierCartRecharge.recharge_price):0,
						give_price:this.cashierCartRecharge.give_price?parseFloat(this.cashierCartRecharge.give_price):0,
					}
					arr[0].daozhang_price = parseFloat(arr[0].recharge_price + arr[0].give_price).toFixed(2);
					
				}
				console.log(arr);
				return arr;
				
			},
			// 实付总价
			getTotalPrice(){
				let total_price=0;
				if(this.cashierCartTabType == 'goods')
				{
					if(this.cashierCart.length>0){
						let price=0;
						for( var i in this.cashierCart){
							if(this.cashierCart[i].type=='goods'){
								if(this.cashierCart[i].sku_id>0){
									price=this.cashierCart[i].sku_original_price;
								}else{
									price=this.cashierCart[i].original_price;
								}
								if(JSON.stringify(this.cashierMember)!='{}'){
									if(this.cashierMember.level_id>0){
										if(this.cashierCart[i].sku_id>0){
											price=this.cashierCart[i].sku_vip_price;
										}else{
											price=this.cashierCart[i].vip_price;
										}
									}
								}
							}else if(this.cashierCart[i].type=='xiangmu'){
								price=this.cashierCart[i].original_price;
							}else{
								price=this.cashierCart[i].market_price
							}
							if(this.cashierCart[i].member_once_card_id)
							{
								price = 0; //如果是选择的套餐里面的话	
							}
							total_price += (parseFloat(price)*100 * this.cashierCart[i].num)/100
						}
					}
				}else if(this.cashierCartTabType == 'taocan')
				{
					if(this.cashierCartTaocan.list.length>0){
						let price=0;
						for( var i in this.cashierCartTaocan.list){
							price=this.cashierCartTaocan.list[i].original_price;
							total_price += (parseFloat(price)*100 * this.cashierCartTaocan.list[i].num)/100
						}
					}
				}else if(this.cashierCartTabType == 'taocan'){
					total_price =  parseFloat(this.cashierCartRecharge.recharge_price)
				}
				return total_price.toFixed(2);
				
			},
			// 不参与折扣总价 is_vip_discount（0不参与，1参与）
			getNoDiscountTotalPrice(){
				let total_price = 0;
				if(this.cashierCartTabType != 'goods')
				{
					return 0;
				}
				if(this.cashierCart.length > 0){
					let price = 0;
					for( var i in this.cashierCart){
						if(JSON.stringify(this.cashierMember) !== '{}'){
							if (this.cashierCart[i].is_vip_discount === 0) price = this.cashierCart[i].vip_price;
						}
						if(this.cashierCart[i].member_once_card_id)
						{
							price = 0; //如果是选择的套餐里面的话	
						}
						total_price += parseFloat(price)
					}
				}
				return total_price.toFixed(2);
			},
			// 商品总数
			getTotalNum(){
				let num=0;
				if(this.cashierCart.length>0){
					for( var i in this.cashierCart){
						num+=this.cashierCart[i].num;
					}
				}
				return num;
			},
			// 应收金额
			getNeedPay(){
				let need_pay=0;
				if(this.cashierCart.length>0){
					let price=0;
					for( var i in this.cashierCart){
						if(this.cashierCart[i].type=='goods'){
							if(this.cashierCart[i].sku_id>0){
								price=this.cashierCart[i].sku_original_price;
							}else{
								price=this.cashierCart[i].original_price;
							}
							if(JSON.stringify(this.cashierMember)!='{}'){
								if(this.cashierMember.level_id>0){
									if(this.cashierCart[i].sku_id>0){
										price=this.cashierCart[i].sku_vip_price;
									}else{
										price=this.cashierCart[i].vip_price;
									}
								}
							}
						}else if(this.cashierCart[i].type=='xiangmu'){
							price=this.cashierCart[i].original_price;
						}else{
							price=this.cashierCart[i].market_price;
						}
						if(this.cashierCart[i].member_once_card_id)
						{
							price = 0; //如果是选择的套餐里面的话	
						}
						need_pay += (parseFloat(price)*100 * this.cashierCart[i].num)/100
					}
				}
				return (need_pay - this.getYouhuiPrice).toFixed(2);
			},
			// 优惠价格
			getYouhuiPrice(){
				console.log(this.cashierMember.level)
				let discount = 1 // 默认无折扣
				let discount_total_price = 0 // 需打折总价
				let youhui_price = 0 // 默认优惠价格0
				if (this.cashierMember.level !== undefined && this.cashierMember.level !== null) { // 非普通会员
					discount = (parseFloat(this.cashierMember.level.discount) / 10).toFixed(2)
					discount_total_price = parseFloat(this.getTotalPrice) - parseFloat(this.getNoDiscountTotalPrice)
					youhui_price = ( discount_total_price - (discount_total_price * discount)).toFixed(2)
				}
				return youhui_price
			}
			// getYouhuiPrice(){
			// 	return ((parseFloat(this.getTotalPrice)*100 - parseFloat(this.getNeedPay)*100)/100).toFixed(2);
			// }
		},
		methods:{
			chooseMemberAct(){
				this.chooseMemberVisible=true;
			},
			cancelChooseMember(){
				this.chooseMemberVisible=false;
			},
			okChooseMember(){
				this.chooseMemberVisible=false;
			},
			chooseIt(index){
				this.selectCurrent=this.pay_type[index].key;
			},
		}
	}
</script>

<style>
	.cash-pay-header{
		background: #F5F5F5;
		width: 100%;
		height: 60px;
		line-height: 60px;
		padding: 0px 20px;
	}
	
	.cash-pay-content{
		border: 1px solid #F5F5F5;
		border-top: none;
	}
	
	.cash-pay-list{
		border-right: 4px solid #F5F5F5;
		/* padding: 20px; */
		width: 45%;
		height: 100%;
	}
	
	.cash-pay-type{
		width: 59%;
		height: 100%;
		padding: 20px;
		border-radius: 4px;
	}
	
	.cash-pay-type-content{
		border: 1px solid #DEE1E7;
		height: 100%;
	}
	
	.cash-pay-type-content-header{
		background: #F5F7FA;
		height: 60px;
		line-height: 58px;
		/* border: 1px solid #E9EBF1; */
		overflow: hidden;
	}
	
	.cash-pay-type-content-header-item{
		height: calc(100% - 2px);
		border-bottom: 1px solid #DEE1E7;
		text-align: center;
	}
	
	.cash-pay-type-content-header-item.active{
		background : #FFFFFF;
		margin-top: 4px;
		border-left: 1px solid #DEE1E7;
		border-right: 1px solid #DEE1E7;
		border-bottom:none;
	}
	
	.blNone{
		border-left: none !important;
	}
	
	.brNone{
		border-right: none !important;
	}
</style>
