<template>
	<div>
		<a-spin :spinning="OrderLoading" tip="创建订单中！！！！">
			<a-spin :spinning="PayLoading" tip="支付中！！！！">
				<div class="flex center pd40 ">
					<div style="width: 378px;">
						<div class="flex space alcenter">
							<div class="cl-main ft20">会员余额</div>
							<div class="cl-price ft20">{{cashierMember.balance}}元</div>
						</div>
						<div class="flex space alcenter mt10">
							<div class="cl-main ft20">总价</div>
							<div class="cl-price ft16">{{getTotalPrice}}元</div>
						</div>
						
						<div class="flex space alcenter mt10" v-if="JSON.stringify(cashierMember)!='{}'  && cashierCartTabType == 'goods'">
							<div class="cl-main ft20">优惠券</div>
							<div class="flex alcenter">
								<div class="cl-price ft16 mr5" v-if="coupon_money>0">抵扣-{{coupon_money}}</div>
								<div class="cl-green ft16" @click="chooseCouponAct()">选择</div>
							</div>
						</div>
						
						<div class="flex space alcenter mt10">
							<div class="cl-main ft20">应收金额</div>
							<div class="cl-price ft16">{{getNeedPay}}元</div>
						</div>
						
						<div class="flex space alcenter mt10" v-if="cashierCartTabType == 'goods'">
							<div class="cl-main ft20">优惠金额</div>
							<div class="cl-green ft16" >{{getYouhuiPrice}}元</div>
						</div>
						
						<div class="flex space alcenter mt10">
							<div class="cl-main ft20">实付金额</div>
							<div>
								<input class="cash-input-box" type="text" size="21" maxlength="21" v-model="getValue" readonly="readonly">
							</div>
						</div>
						<a-form-model-item required label="备注" class="cl-main ft20">
							<a-textarea v-model="remark"  :rows="5" />
						</a-form-model-item>
						<div id="cash-btn-list" class="mt10">
							<slot>
								<div @click="inputValue('7')" class=" btn-30 btn-radius clear-marginleft">7</div>
								<div @click="inputValue('8')" class=" btn-30 btn-radius">8</div>
								<div @click="inputValue('9')" class=" btn-30 btn-radius">9</div>
								<div @click="inputValue('0')" class=" btn-30 btn-radius " >0</div>
								<div @click="inputValue('.')" class=" btn-30 btn-radius "  >.</div>
								<div @click="inputValue('4')" class=" btn-30 btn-radius clear-marginleft">4</div>
								<div @click="inputValue('5')" class=" btn-30 btn-radius">5</div>
								<div @click="inputValue('6')" class=" btn-30 btn-radius">6</div>
								<div @click="clearValue()" class=" btn-30 btn-radius color-red ">C</div>
								<div @click="backValue()" class=" btn-30 btn-radius color-red font-14 ">←</div>
								<div @click="inputValue('1')" class=" btn-30 btn-radius clear-marginleft">1</div>
								<div @click="inputValue('2')" class=" btn-30 btn-radius">2</div>
								<div @click="inputValue('3')" class=" btn-30 btn-radius">3</div>
								<div @click="calValue()" class=" btn-70 btn-radius color-red font-14 ">确认</div>
							</slot>
						</div>
					</div>
					
					<div v-if="chooseCouponVisible">
						<choose-coupon :visible="chooseCouponVisible" :member_id="member_id" :coupon_id="coupon_id"  :total_price="getTotalPrice"
						v-model="member_coupon" @cancel="cancelChooseCoupon" @ok="okChooseCoupon"></choose-coupon>
					</div>
					
				</div>
			</a-spin>
		</a-spin>
	</div>
</template>

<script>
	import chooseCoupon from './modal/chooseCoupon.vue';
	export default{
		components:{
			chooseCoupon,
		},
		data(){
			return{
				chooseCouponVisible:false,
				precision:2,
				inputShow: {
					value: '0'
				},
				member_coupon:{},
				OrderLoading:false,
				PayLoading: false,
				remark:''
			}
		},
		computed: {
			getValue: function() {
				return this.inputShow.value
			},
			member_id(){
				let member_id=0;
				if(JSON.stringify(this.cashierMember)!='{}'){
					member_id=this.cashierMember.member_id;
				}
				return member_id;
			},
			coupon_id(){
				let coupon_id=0;
				if(JSON.stringify(this.cashierMember)!='{}'){
					if(JSON.stringify(this.member_coupon)!='{}'){
						if(this.member_coupon.member_id == this.member_id){
							coupon_id=this.member_coupon.member_coupon_id;
						}
					}
				}
				return coupon_id;
			},
			// 优惠券
			coupon_money(){
				let coupon_money=0;
				if(JSON.stringify(this.cashierMember)!='{}'){
					if(JSON.stringify(this.member_coupon)!='{}'){
						if(this.member_coupon.member_id == this.member_id){
							coupon_money=this.member_coupon.coupon_money;
						}
					}
				}
				return parseFloat(coupon_money).toFixed(2);
			},
			// 实付总价
			getTotalPrice(){
				let total_price=0;
				if(this.cashierCartTabType == 'goods')
				{
					if(this.cashierCart.length>0){
						let price=0;
						for( var i in this.cashierCart){
							if(this.cashierCart[i].type=='goods'){
								if(this.cashierCart[i].sku_id>0){
									price=this.cashierCart[i].sku_original_price;
								}else{
									price=this.cashierCart[i].original_price;
								}
								if(JSON.stringify(this.cashierMember)!='{}'){
									if(this.cashierMember.level_id>0){
										if(this.cashierCart[i].sku_id>0){
											price=this.cashierCart[i].sku_vip_price;
										}else{
											price=this.cashierCart[i].vip_price;
										}
									}
								}
							}else if(this.cashierCart[i].type=='xiangmu'){
								price=this.cashierCart[i].original_price;
							}else{
								price=this.cashierCart[i].market_price;
							}
							if(this.cashierCart[i].member_once_card_id)
							{
								price = 0; //如果是选择的套餐里面的话	
							}
							console.log('price',price)
							total_price += (parseFloat(price)*100 * this.cashierCart[i].num)/100
						}
					}
				}else if(this.cashierCartTabType == 'taocan')
				{
					if(this.cashierCartTaocan.list.length>0){
						let price=0;
						for( var i in this.cashierCartTaocan.list){
							price=this.cashierCartTaocan.list[i].original_price;
							total_price += (parseFloat(price)*100 * this.cashierCartTaocan.list[i].num)/100
						}
					}
				}else if(this.cashierCartTabType == 'chongzhi'){
					total_price =  parseFloat(this.cashierCartRecharge.recharge_price)
				}
				return total_price.toFixed(2);
			},
			// 不参与折扣总价 is_vip_discount（0不参与，1参与）
			getNoDiscountTotalPrice(){
				if(this.cashierCartTabType != 'goods')
				{
					return 0;
				}
				let total_price = 0;
				if(this.cashierCart.length > 0){
					let price = 0;
					for( var i in this.cashierCart){
						if(JSON.stringify(this.cashierMember) !== '{}'){
							if (this.cashierCart[i].is_vip_discount === 0) price = this.cashierCart[i].vip_price;
						}
						if(this.cashierCart[i].member_once_card_id)
						{
							price = 0; //如果是选择的套餐里面的话	
						}
						total_price += parseFloat(price)
					}
				}
				return total_price.toFixed(2);
			},
			// 应收金额
			getNeedPay(){
				let need_pay=0;
				need_pay=(parseFloat(this.getTotalPrice)*100 - parseFloat(this.coupon_money)*100 - parseFloat(this.getYouhuiPrice)*100)/100
				this.inputShow.value = need_pay.toFixed(2)
				return need_pay.toFixed(2);
			},
			// 优惠价格
			getYouhuiPrice(){
				if(this.cashierCartTabType != 'goods')
				{
					return 0;
				}
				let discount = 1 // 默认无折扣
				let discount_total_price = 0 // 需打折总价
				let youhui_price = 0 // 默认优惠价格0
				if (this.cashierMember.level !== undefined && this.cashierMember.level !== null) { // 非普通会员
					discount = (parseFloat(this.cashierMember.level.discount) / 10).toFixed(2)
					discount_total_price = parseFloat(this.getTotalPrice) - parseFloat(this.getNoDiscountTotalPrice)
					youhui_price = ( discount_total_price - (discount_total_price * discount)).toFixed(2)
				}
				return youhui_price
			},
			goods(){
				let arr=new Array;
				if(this.cashierCartTabType == 'goods')
				{
					if(this.cashierCart.length>0){
						for( var i in this.cashierCart){
							var info = this.cashierCart[i];
							if(info.type=='goods'){
								arr.push({
									goods_id:info.goods_id,
									num:info.num,
									sku_id:info.sku_id,
									fcUserList:info.fcUserList?info.fcUserList:[],
									type:info.type,
									tk_xm:info.member_once_card_id?{
										member_once_card_id:info.member_once_card_id?info.member_once_card_id:0,
										once_card_id:info.once_card_id?info.once_card_id:0,
										once_card_order_id:info.once_card_order_id?info.once_card_order_id:0,
										member_once_card_item_id:info.member_once_card_item_id?info.member_once_card_item_id:0,
										is_zs:info.is_zs?info.is_zs:0,
									}:''
								})
							}else if(info.type=='xiangmu'){
								console.log('info',info)
								arr.push({
									goods_id:info.goods_id,
									num:info.num,
									sku_id:info.sku_id,
									fcUserList:info.fcUserList?info.fcUserList:[],
									type:info.type,
									tk_xm:info.member_once_card_id?{
										member_once_card_id:info.member_once_card_id?info.member_once_card_id:0,
										once_card_id:info.once_card_id?info.once_card_id:0,
										once_card_order_id:info.once_card_order_id?info.once_card_order_id:0,
										member_once_card_item_id:info.member_once_card_item_id?info.member_once_card_item_id:0,
										is_zs:info.is_zs?info.is_zs:0,
									}:''
								})
							}
							
						}
					}
				}else if(this.cashierCartTabType == 'taocan')
				{
					this.cashierCartTaocan.list.forEach((item,index)=>{
						var zsGoods = (
							this.cashierCartTaocan && this.cashierCartTaocan.zsGoodsListObj 
							&& this.cashierCartTaocan.zsGoodsListObj[item.goods_id])?
							this.cashierCartTaocan.zsGoodsListObj[item.goods_id]:[];
						var zsXm = (
							this.cashierCartTaocan && this.cashierCartTaocan.zsXmListObj 
							&& this.cashierCartTaocan.zsXmListObj[item.goods_id])?
							this.cashierCartTaocan.zsXmListObj[item.goods_id]:[]	
						arr.push({
							goods_id:item.goods_id,
							num:item.num,
							sku_id:0,
							fcUserList:item.fcUserList?item.fcUserList:[],
							type:item.type,
							zsGoods,zsXm
						})
					})
				}else if(this.cashierCartTabType == 'chongzhi')
				{
					arr.push({
						goods_id:0,
						num:1,
						sku_id:0,
						fcUserList:this.cashierCartRecharge.fcUserList?this.cashierCartRecharge.fcUserList:[],
						type:'chuzhika',
					})
				}
				
				
				return arr;
			}
		},
		methods:{
			
			//选择优惠券
			chooseCouponAct(){
				if(this.member_id==0){
					this.$message.error('游客无法选择优惠券');
					return false;
				}
				this.chooseCouponVisible=true;
			},
			cancelChooseCoupon(){
				this.chooseCouponVisible=false;
			},
			okChooseCoupon(){
				this.chooseCouponVisible=false;
			},
			
			//计算器
			inputValue(param) {
				if (Object.prototype.toString.call(this.inputShow.value) == "[object Number]") { //判断输入框内容是否为数字类型
					this.inputShow.value = "0"; //数字类型说明是上个计算结果,清空内容
				}
				var str = '' + this.inputShow.value; //输入内容时,将输入框内容转为字符串类型
				
				if(this.precision==0){
					if(param=='.'){
						return;
					}
					var num = ('' + parseInt(str.split('').reverse().join(''))).split('').reverse().join('');
					var nlen = num.length;
					console.log(num);
					if ((num == '0' )) { //输入框内最后一串数字不为0时拼接字符串
						this.inputShow.value = str.substring(0, str.length - nlen) + param; //输入框内最后一串数字为0时,删除0拼接
					}else{
						this.inputShow.value += param; //拼接输入内容
					}
				}else{
					if(str.indexOf(".") == -1){
						var num = ('' + parseFloat(str.split('').reverse().join(''))).split('').reverse().join('');
						var nlen = num.length;
						console.log(num);
						if ((num == '0' )) { //输入框内最后一串数字不为0时拼接字符串
							if(param == '.'){
								this.inputShow.value += param; //拼接输入内容
							}else{
								this.inputShow.value = str.substring(0, str.length - nlen) + param; //输入框内最后一串数字为0时,删除0拼接
							}
						}else{
							this.inputShow.value += param; //拼接输入内容
						}
					}else{
						if(param != '.'){
							if(str.split('.')[1].length>=this.precision){
								return;
							}
							this.inputShow.value += param; //拼接输入内容
						}
					}
				}
				
			},
			clearValue() { //清空输入框内容
				this.inputShow.value = '0';
			},
			backValue() { //删除键,删除单个字符
				var str = this.inputShow.value;
				if (str.length == 1) {
					this.inputShow.value = "0";
				} else {
					this.inputShow.value = str.slice(0, str.length - 1);
				}
			},
			
			calValue(){
				let value = parseFloat(this.inputShow.value);
				if (value !== parseFloat(this.getNeedPay)) {
					// this.$message.warning('实付金额与应收金额不一致')
					// return
				}
				if(this.OrderLoading==true) return;
				this.OrderLoading=true;
				var obj = {
					cashier_cart_tab_type:this.cashierCartTabType,
					pay_type:'money',
					sel_pay:'',
					member_id:this.member_id,
					total_price:this.getTotalPrice,
					give_price:(this.cashierCartTabType == 'chongzhi')?parseFloat(this.cashierCartRecharge.give_price):0,
					coupon_id:this.coupon_id,
					youhui_price:this.getYouhuiPrice,
					true_pay:value,
					goods:this.goods,
					remark:this.remark,
				}
				// console.log('this.goods',this.goods);
				// return ;
				this.$http.api('createOrder',obj).then(res=>{
					this.OrderLoading=false;
					//去支付
					if(this.PayLoading==true) return;
					this.PayLoading=true;
					this.$http.api('moneyPay',{
						order_id:res.order_id,
					}).then(res=>{
						this.PayLoading=false;
						this.$message.success('支付成功',1,()=>{
							// this.$store.commit('setCashierCart',[]);
							// this.$store.commit('setCashierCartIndex',-1);
							// this.$store.commit('setMember',{});
							switch(this.cashierCartTabType)
							{
								case 'goods':
									this.$store.commit('setCashierCart',[]);
									this.$store.commit('setCashierCartIndex',-1);
									break;
								case 'taocan':
									var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
									if(cashierCartTaocan.type == 1)
									{
										cashierCartTaocan.list = [];
										cashierCartTaocan.selListIndex = -1;
										cashierCartTaocan.zsGoodsListObj = {};
										cashierCartTaocan.zsXmListObj = {};
									}
									this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
									break;
								case 'chongzhi':
									this.$store.commit('setCashierCartRecharge',{});
									break;	

							}
							this.$store.commit('setMember',{});
							this.$router.push('/index');
						})
					}).catch(res=>{
						this.PayLoading=false;
					})
				}).catch(res=>{
					this.OrderLoading=false;
				})
			}
		}
	}
</script>

<style>
	.cash-input-box {
		margin: 0;
		width: 250px;
		padding: 18px 10px;
		height: 50px;
		border: 1px solid #e5e5e5;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		background: #FFF;
		text-align: right;
		line-height: 50px;
		font-size: 24px;
		font-family: Verdana, Geneva, sans-serif;
		color: #666;
		outline: none;
		text-transform: uppercase;
	}
	#cash-btn-list {
		width: 378px;
		overflow: hidden;
	}
	
	#cash-btn-list .btn-radius {
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border: 1px solid #e5e5e5;
		background: -webkit-gradient(linear, 0 0, 0 100%, from(#f7f7f7), to(#ebebeb));
		background: -moz-linear-gradient(top, #f7f7f7, #ebebeb);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#f7f7f7, endColorstr=#ebebeb, grandientType=1);
		line-height: 58px;
		text-align: center;
		text-shadow: 0px 1px 1px #FFF;
		font-weight: bold;
		font-family: Verdana, Geneva, sans-serif;
		color: #666;
		float: left;
		margin-left: 22px;
		margin-top: 22px;
		font-size: 24px;
		cursor: pointer;
	}
	
	#cash-btn-list .btn-radius:active {
		background: #ffffff;
	}
	
	#cash-btn-list .clear-marginleft {
		margin-left: 0;
	}
	
	#cash-btn-list .font-14 {
		font-size: 14px;
	}
	
	#cash-btn-list .color-red {
		color: #ff5050
	}
	
	#cash-btn-list .color-blue {
		color: #00b4ff
	}
	
	#cash-btn-list .btn-30 {
		width: 58px;
		height: 58px;
	}
	
	#cash-btn-list .btn-70 {
		width: 140px;
		height: 58px;
	}
	
	#cash-btn-list .btn-70 {
		width: 138px;
		height: 58px;
	}
</style>
