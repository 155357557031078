<template>
	<div>
		<a-modal title="选择优惠券" :width="540" :visible="visible"  @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div>
					<div>
						<a-button type="primary" @click="noChooseAct()">不选择优惠券</a-button>
					</div>
					
					<div class="mt20" v-if="datas.length>0">
						<div class="coupon-index-item" v-for="(item,index) in datas">
								<div class="flex space alcenter">
									<div>
										<div class="text-over4 ft14 ftw600 cl-black">{{item.name}}</div>
										<div class="mt15 ft12 ftw400 cl-notice text-over4">满{{item.money_limit}}元可用</div>
										<div class="mt10 ft12 ftw400 cl-info text-over4">有效期：{{item.valid_bg_time}}-{{item.valid_end_time}}</div>
									</div>
									
									<div>
										<div class=" flex end">
											<div class="flex alcenter">
												<div class="ft12 ftwblod cl-price">¥</div>
												<div class="ft24 ftwblod cl-price">{{item.coupon_money}}</div>
											</div>
										</div>
										
										<div class="mt10 flex end">
											<a-button type="default"  v-if="coupon_id == item.member_coupon_id">已选择</a-button>
											<a-button type="primary" v-else @click="chooseIt(index)">选择</a-button>
											
										</div>
									</div>
								</div>
							</div>	
							
						
					</div>
					
					<div class="mt20 flex center" v-else>
						<a-empty/>
					</div>
								
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	export default{
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
			visible:{
				type:Boolean,
				default:false,
			},
			coupon_id:{
				type:Number,
				default:0,
			},
			member_id:{
				type:Number,
				default:0,
			},
			total_price:{
				type:String,
				default:'0',
			}
		},
		mixins:[listMixin],
		data(){
			return {
				loading:false,
				confirmLoading:false,
				pagination: {
					current: 1,
					pageSize: 3, //每页中显示10条数据
					total: 0,
				},
				datas:[]
			}
		},
		
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('getChooseCouponList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					member_id:this.member_id,
					total_price:this.total_price,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
				
			chooseIt(index){
				let value= this.datas[index];
				this.$emit('input',value);
				this.$emit("ok");
			},
			
			noChooseAct(){
				let value= new Object;
				this.$emit('input',value);
				this.$emit("ok");
			}
		}
	}
</script>

<style>
	.coupon-index-item{
		width: 99%;
		background: #F7F8FC;
		border-radius: 4px;
		margin-right: 1%;
		margin-bottom:1%;
		padding: 20px;
	}
</style>
